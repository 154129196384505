import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';


const BaeMinJi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "변종욱 ♥ 배민지 결혼합니다.";
    const openDesc = "3월 15일 토요일 12시 20분\n마산 힐스카이 10층 힐그랜드홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/87/thumb.jpg";

    const placeTelNum = "055-291-9500";

    const lng = 128.601370235693;
    const lat = 35.2190229399847

    const forBus = "봉암공단 입구에서 하차\n[간선] 107, 114, 116, 160, 163, 164\n[지선]257\n[좌석] 740, 3002";
    const forCar = "[내부 주차장]\n경남 창원시 마산회원구 봉양로 133 \n(힐스카이 웨딩&컨벤션] \n\n[외부 주차장]\n경남 창원시 마산회원구 봉암공단로 9\n(동방 창원지사 / 2시간 무료, 추가 30분당 1000원)"


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const msg1 = "바라만 봐도 떨리던 설렘은 포근한 사랑이 되어\n서로의 곁에 가장 잘 어울리는 존재가 되었습니다.\n저희 두 사람 평생의 손을 잡으려 합니다.";
    const msg2 = "예쁜 빛이 두 사람 어깨에 내려앉는 봄날\n가장 가까운 자리에서 따뜻한 축하를 더해주세요.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "변종욱",
            bank: "카카오뱅크",
            accountNumber : "3333-24-0266427",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FSRr2OyNn"
        },
        {
            role: "신랑 부",
            name: "변민석",
            bank: "국민은행",
            accountNumber : "846602-01-023196",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김은하",
            bank: "농협은행",
            accountNumber : "815143-52-250067",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "배민지",
            bank: "카카오뱅크",
            accountNumber : "3333-17-5688759",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7nfnPCC"
        },
        {
            role: "신부 부",
            name: "배종진",
            bank: "경남은행",
            accountNumber : "502-21-0234786",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "정성례",
            bank: "농협은행",
            accountNumber : "821113-56-081265",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "변종욱",
            call: "010-6636-5638"
        },
        {
            role: "신랑 부",
            name: "변민석",
            call: "010-3862-5638"
        },
        {
            role: "신랑 모",
            name: "김은하",
            call: "010-7331-5638"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "배민지",
            call: "010-5430-9890"
        },
        {
            role: "신부 부",
            name: "배종진",
            call: "010-5430-0303"
        },
        {
            role: "신부 모",
            name: "정성례",
            call: "010-3882-3777"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "후련할 거 같아!",
            brideAnswer: "신혼여행❤"
        },
        {
            question : "우리의 결혼에서 가장 중요한 가치는?",
            groomAnswer: "peace",
            brideAnswer: "fidelity"
        },
        {
            question : "우리의 인생의 목표는?",
            groomAnswer: "건강하게 오래 살기",
            brideAnswer: "순간을 즐기며 행복하게"
        },
        {
            question : "10년, 20년 후 우리의 모습은 어떤 모습일까?",
            groomAnswer: "변치 않을거야.",
            brideAnswer: "어른이 될거야."
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑해 영원히.",
            brideAnswer: "덕분에 행복해."
        }
    ]


    const informationList =[
        {
            title : "피로연 안내",
            content : "25년 3월 1일 \n 서귀포시 대정읍 하모리 동성수산"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"저희 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default BaeMinJi;