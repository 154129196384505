import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';


const SeoMinJeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이찬호 ♡ 서민정 결혼합니다. ";
    const openDesc = "2025년 06월 14일 토요일";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/74/thumb.JPG";

    // const placeTelNum = "061-689-0856";

    const forParking = "외부 주차장 (2시간 무료)\n트리플렉스 - 경기 수원시 영통구 매영로345번길 7\n 영통공영주차장 - 경기 수원시 영통구 봉영로 1598";
    const forSubway = "수인분당선 영통역 8번출구 (도보2분)";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        // place:'',
        // address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "이찬호",
            bank: "국민은행",
            accountNumber : "945802-00847892",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "이용현",
            bank: "우리은행",
            accountNumber : "1002-609-292408",
            isKakao : false
        },

        {
            role: "신랑 모",
            name: "유순희",
            bank: "농협은행",
            accountNumber : "485081-51-047146",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "서민정",
            bank: "카카오뱅크",
            accountNumber : "3333-14-1501689",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "서강일",
            bank: "농협은행",
            accountNumber : "815086-52-246004",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김명희",
            bank: "부산은행",
            accountNumber : "112-2060-8419-09",
            isKakao : false
        }
    ]

    const msg1 = "2019년 어느 봄날 연인이 되어 \n6년의 사계절을 함께 보냈습니다. ";
    const msg2 = "좋은 친구로 , 사랑하는 연인으로 \n아껴주고 사랑하면 살겠습니다. ";
    const msg3 = "저희는 식을 치르는 대신 사랑하는 양가 부모님께\n감사드리는 자리를 가지기로 했습니다. ";
    const msg4 = "감사한 분들을 한자리에 모시지 못해 아쉽지만 \n따듯한 응원과 축하의 말씀 해주신다면 \n더없는 기쁨으로 간직하겠습니다.";

    const lng = 127.643422287354;
    const lat = 34.7333890318737;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "오 귀여운데",
            brideAnswer: "와 진짜 크다"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "잘 살아보자",
            brideAnswer: "더 행복하자"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "평생 내꺼야",
            brideAnswer: "평생 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "나 장가간다",
            brideAnswer: "유부초밥🍣"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "🖤소중한 날",
            brideAnswer: "함께해요🖤"
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이찬호",
            call: "010-4135-5933"
        },
        {
            role: "신랑 부",
            name: "이용현",
            call: "010-5598-5931"
        },
        {
            role: "신랑 모",
            name: "유순희",
            call: "010-2883-5933"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "서민정",
            call: "010-8574-9824"
        },
        {
            role: "신부 부",
            name: "서강일",
            call: "010-3831-9824"
        },
        {
            role: "신부 모",
            name: "김명희",
            call: "010-8940-6386"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        // place: response.data.data.place,
                        // address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter isFormatWeddingTime={true} width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} isFormatWeddingTime={true}/>
                    <Letter title={"저희 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} isFormatWeddingTime={true} groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList2 conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    {/* <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/> */}
                                    {/* <Location_Desc forParking={forParking} forSubway={forSubway} conceptStyle={conceptStyle}/> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default SeoMinJeong;