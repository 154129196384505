import React, { useState, useEffect } from 'react';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import winterMusicFile from "../asset/music/winter.mp3";
import Main_Summer from '../component/Summer/Main_Summer';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Video from '../component/Video';
import Information from '../component/Information';

const LeeSuYeon2 = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김성균 ♥ 이수연 결혼합니다.";
    const openDesc = "2025년 3월 16일 일요일 오전 11시\n창원 리베라컨벤션 7층 그랜드볼룸";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/52/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });
    
    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#738244",
        pointColor: "#738244",
        btnBgColor: "#738244",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}


    const groomAccountList = [
        {
            role: "신랑",
            name: "김성균",
            bank: "농협은행",
            accountNumber : "352-1933-7101-33",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7i0zYrw"
        },
        {
            role: "신랑 부",
            name: "김맹진",
            bank: "국민은행",
            accountNumber : "854725-95-104434",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "공민순",
            bank: "농협은행",
            accountNumber : "178071-52-076275",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이수연",
            bank: "부산은행",
            accountNumber : "112-2298-5726-04",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7odft46"
        },
        {
            role: "신부 부·모",
            name: "이철희·김희정",
            bank: "농협은행",
            accountNumber : "824-12-249811",
            isKakao : false
        },
    ]


    const parents = [
        {groom : "김맹진.공민순의 장남"},
        {bride : "이철희.김희정의 장녀"}
    ]

    const msg1 = "저희 두 사람,\n그 어느 때보다도 설레고 특별한\n3월을 맞이하려고 합니다.";
    const msg2 = "부부로서의 인연을 맺는 그날\n따뜻한 축하와 격려로\n새로운 시작을 빛내주시면 감사하겠습니다.";

    const lng = 128.680493916033;
    const lat = 35.2232489309814;
    
    const placeTelNum = "055-282-2600";

    const forCar = "경남 창원시 성산구 중앙대로100번길 9";
    const forBus = "창원종합버스터미널 -> 리베라컨벤션\n택시 : 약 5.3km (약10분)\n버스 : 151, 115, 106, 103";
    const forTrain2 = "창원중앙역 -> 리베라컨벤션\n택시 : 약 3.2km (약7분)\n버스 : 3004, 3000, 215, 156\n\n창원역 -> 리베라컨벤션\n택시 : 약 9.2km (약15분)\n버스 : 6000, 5000, 710, 115, 113";
    const forParking = "본관 건물 주차 2시간 무료 \n\n 외부 지정 주차장 1시간 무료 \n- 공영주차타워주차장 500대 \n- 엠포리움 주차장 170대 \n - 범한빌딩 주차장 150대 \n - 국민은행 주차장 \n- 미성 주차장 \n- 수협 주차장 100대 \n- STX 주차장 380대";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "너에게 결혼은 어떤 의미야?",
            groomAnswer: "행복한 가정",
            brideAnswer: "하나보단 둘"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "이제 실감나",
            brideAnswer: "너무 떨려요"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "평생 잘할게",
            brideAnswer: "평생 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "한번 해보자",
            brideAnswer: "너만 잘해!"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "서로만 보며",
            brideAnswer: "예쁘게 살자"
        }
    ]

    const informationList =[
        {
            title : "화환 안내",
            content : "예식장 규정상 쌀화환 제도를 \n시행하고 있습니다.\n\n의미 있는 나눔으로 시작할 수 있도록 \n@쌀화환@을 보내주시면 감사하겠습니다."
        }
    ]


    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

        // ex. Kim Gayeong
        const getRemoveSpaceName = (fullName) => {
            const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
            const firstName = nameParts[0]; // 첫 번째 단어 유지
            const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
            return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
        };
        

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#648900"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
            <Main_Summer weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} top={"5%"} bottom={"82%"} bottom2={"74%"} dateBottom={"3%"} />
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                    <Video width={"90%"} url={"https://moodsean.s3.ap-northeast-2.amazonaws.com/52/video2.mp4"} conceptStyle={conceptStyle}/>
                </MotionWrapper>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                                <Location_Spring level={4} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forTrain2={forTrain2} forParking={forParking} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LeeSuYeon2;
