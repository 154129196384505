import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import ParentInfo from '../component/Info/ParentInfo';


const HwangJiHye = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "최규직 💖 황지혜 결혼합니다.";
    const openDesc = "2025년 3월 22일 토요일\n루이비스컨벤션 1층 아모리스홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/97/thumb.jpg";

    const placeTelNum = "042-933-2500";

    const lng = 127.398058220361;
    const lat =  36.424742248149;


    const forBus = "** 유성시외버스터미널에서 오는 법 \n - 704번버스 승차 → 롯데마트대덕점 하차 → 도보 15분\n\n** 복합터미널에서 오는법 \n- 701번버스 승차 → 대덕밸리용신교 하차 → 도보5분\n - 802번버스 승차 → 롯데마트대덕점 하차 → 도보15분 "
    const forSubway = "** 신탄진역에서 오는 법 \n705번버스 승차 → 현대아울렛북문 하차 → 도보5분\n\n** 대전역에서 오는 법 \n701번버스 승차 → 대덕밸리용신교 하차 → 도보5분";
    const forParking = "호텔&컨벤션 주차장 : 지상 주차\n남문 주차장 : 지상&지하 주차\n북문 주차장 : 지상&지하 주차\n외부주차장 : 대덕비즈센터 \n(대전광역시 유성구 테크노 4로 17), 셔틀버스 상시 운행";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // 딥로즈 핑크 : #BC5078
    // 빈티지 로즈 : #D77599
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const msg1 = "같이 있으면 매일이 즐겁고\n서로일 때 가장 나다워지는 평생의 반려자와\n새로운 인생을 시작하려 합니다.";
    const msg2 = "저희 두 사람의 첫걸음을 함께하여\n자리를 빛내주시면\n더없이 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "최규직",
            bank: "우리은행",
            accountNumber : "1002-150-646235",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej85mLKPu"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이청현",
        //     bank: "신한은행",
        //     accountNumber : "110-499-862760",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "김선해",
            bank: "농협은행",
            accountNumber : "741022-52-206532",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "황지혜",
            bank: "하나은행",
            accountNumber : "642-910478-48107",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9TxoJq8"
        }
        ,{
            role: "신부 부",
            name: "황길선",
            bank: "하나은행",
            accountNumber : "628-141805-00107",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김효숙",
            bank: "농협은행",
            accountNumber : "407-02-096581",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FW5jA5Uss"
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이영인",
            call: "010-4789-2824"
        },
        {
            role: "신랑 부",
            name: "이청현",
            call: "010-4412-2824"
        },
        {
            role: "신랑 모",
            name: "이병순",
            call: "010-5437-2824"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이보은",
            call: "010-6487-7597"
        },
        {
            role: "신부 부",
            name: "이성열",
            call: "010-5402-7597"
        },
        {
            role: "신부 모",
            name: "유선희",
            call: "010-5558-7597"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫눈에 반함",
            brideAnswer: "너무 떨렸어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "난 기대돼ෆ"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "넌 이제 내꺼",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "결혼을 앞두고 부모님들께 드리고싶은 말은?",
            groomAnswer: "감사합니다",
            brideAnswer: "사랑해요ෆ"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이ෆ"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const parentInfo = {
        groom: {
            dad: { name: "최성하", isDeceased: true },
            mom: { name: "김선해", isDeceased: false },
            role: "장남",
        },
        bride: {
            dad: { name: "황길선", isDeceased: false },
            mom: { name: "김효숙", isDeceased: false },
            role: "장녀",
        }
    };

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring isBold={true} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter isBold={true} title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData} isBold={true}/>
                    </MotionWrapper>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"253px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                    </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default HwangJiHye;