import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Fall from '../component/Fall/Main_Fall';
import CallInfo from '../component/CallInfo';
import musicFile from '../asset/music/소수빈-01-그대라는 선물.mp3'

const GoHuiSong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "박성빈 ♥ 고희송 결혼합니다.";
    const openDesc = "2025년 3월 22일 오후 12시 \n그랜드 인터컨티넨탈 서울 파르나스";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/29/thumb.jpeg";
    
    const placeTelNum = "02. 555. 5656";

    const locationDesc = "※ 봉은사역 방면 인터컨티넨탈 코엑스와 혼동하지 않도록 \n유의하시기 바랍니다.";
    const forCar = "서울특별시 강남구 테헤란로 521 그랜드 인터컨티넨탈 서울 파르나스\n- 호텔 지하 주차장 이용 시 4시간 무료 주차";
    const forBus = "[지하철 2호선 삼성역]\n146, 333, 341, 360, 740, N13, N61, 3411, 500-2, 1100, \n1700, 2000, 2000-1, 7007, 9303, 8001, P9601, P9602, \nM6450, 강남07, 강남08\n\n[지하철 2호선 삼성역]\n11-3, 917, 143, 146, 350, 401, 2413, 3422, 4318, 4319, \n4419, 6900, 9407, 9507, 9607\n\n[무역센터]\n143, 2413, 4419, 500-2, 9407, 9507, 9607, 강남01, \n강남06, 강남08";
    const forSubway = "지하철 2호선 삼성역\n5번 출구에서 도보로 1분 \n(지하로 오실 경우 파르나스몰 내 연결 엘리베이터를 이용하실 수 있습니다.)";
    const forTrain = "SRT로 오시는 경우 \n지하철 수서역에서 분당선 승차 \n→ 선릉역에서 2호선 환승 → 삼성역에서 하차\n\n버스\n333, 3425, 6900, 강남06, 401, 4419"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "박성빈",
            bank: "국민은행",
            accountNumber : "230102-04-237027",
            isKakao :true,
            kakaoUrl : "https://qr.kakaopay.com/Fc3nbIZiy"
        },
        {
            role: "신랑 부",
            name: "박홍숙",
            bank: "기업은행",
            accountNumber : "010-6361-4000",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "안은미",
            bank: "국민은행",
            accountNumber : "294-21-0446-056",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "고희송",
            bank: "새마을금고",
            accountNumber : "9003-2814-5736-7",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FJF5Cvfp6"
        },
        {
            role: "신부 부",
            name: "고제근",
            bank: "농협은행",
            accountNumber : "415013-52-159614",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "김영자",
            bank: "농협은행",
            accountNumber : "356-0555-5913-13",
            isKakao : false
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "고희송",
            call: "010-3027-1180"
        },
        {
            role: "신부 부",
            name: "고제근",
            call: "010-5486-2180"
        },
        {
            role: "신부 모",
            name: "김영자",
            call: "010-4211-7796"
        }
    ]


    const groomCallInfoList = [
        {
            role: "신랑",
            name: "박성빈",
            call: "010-5064-0388"
        },
        {
            role: "신랑 부",
            name: "박홍숙",
            call: "010-6361-4000"
        },
        {
            role: "신랑 모",
            name: "안은미",
            call: "010-6456-4307"
        }
    ]

    const msg1 = "3월의 따스한 봄날 \n저희 두 사람이 평생을 약속하려 합니다."
    const msg2 = "새로운 시작을 약속하는 뜻깊은 날에 \n귀한 걸음하시어 축복해주시면 감사하겠습니다.";

    const lng = 127.06080691987;
    const lat = 37.5091916919873;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // Park Seo Young -> Park Seo young
    const toLowerCaseString = (str) => {
        const parts = str.split(" ");
        if (parts.length < 2) return str; // 성과 이름이 없으면 그대로 반환

        // 첫 단어(성)는 그대로 유지
        const firstPart = parts[0];

        // 두 번째 단어 이후로 처리
        const restParts = parts.slice(1).map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // 첫 단어만 첫 글자 대문자
                : part.toLowerCase() // 나머지는 소문자로 변환
        );

        return `${firstPart} ${restParts.join(" ")}`;
    };
    

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Fall brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} conceptStyle={conceptStyle} />
                    <Letter title={"초대합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc desc={locationDesc} forCar={forCar} forTrain={forTrain} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default GoHuiSong;