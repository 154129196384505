import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import moreBtn from "../css/moreBtn.png";
import foldBtn from "../css/foldBtn.png";
import copyImg from '../css/copy.png';
import phoneImg from '../css/call.png';
import MotionWrapper from './MotionWrapper';

const CallInfo = ({ conceptStyle, brideCallInfoList, groomCallInfoList }) => {
    const [isGroomOpen, setIsGroomOpen] = useState(false);
    const [isBrideOpen, setIsBrideOpen] = useState(false);

    const toggleGroom = () => setIsGroomOpen(!isGroomOpen);
    const toggleBride = () => setIsBrideOpen(!isBrideOpen);

    const handleCopyClipBoardToCall = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            alert("전화번호가 복사되었습니다🤍");
        } catch (err) {
            console.log(err);
        }
    };

    const renderCallInfoList = (list) => {
        return list.map((info, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "center", margin: "5px 0", alignItems: "center" }}>
                <p style={{ width: "60px", margin: "0" }}>{info.role}</p>
                <p style={{ width: "55px", margin: "0" }}>{info.name}</p>
                <p style={{ margin: "0 5px" }}>{info.call}</p>
                <a href={`tel:${info.call}`}>
                    <img
                        className="phone_img"
                        src={phoneImg}
                        alt="Call"
                        style={{ cursor: "pointer", width: "14px", marginLeft: "5px" }}
                    />
                </a>
                <img className='copy_img' src={copyImg} alt='Copy Image' onClick={() => handleCopyClipBoardToCall(info.call)}></img>
            </div>
        ));
    };

    return (
        <div style={{ width: "100vw", textAlign: "center", fontFamily: conceptStyle.fontFamilyKo, marginBottom: "80px", marginTop: "80px" }}>
            {/* 신랑측 */}
            <MotionWrapper>
            <div style={{ marginBottom: "2rem" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={toggleGroom}>
                    <p style={{ margin: 0 }}>신랑측 연락하기</p>
                    <img
                        style={{ width: "15px", height: "10px", marginLeft: "7px" }}
                        src={isGroomOpen ? foldBtn : moreBtn}
                        alt={isGroomOpen ? "Fold Button" : "More Button"}
                    />
                </div>
                <Collapse isOpen={isGroomOpen}>
                    <div style={{ fontSize: "14px", marginTop: "10px" }}>
                        {renderCallInfoList(groomCallInfoList)}
                    </div>
                </Collapse>
            </div>
            </MotionWrapper>
            {/* 신부측 */}
            <MotionWrapper>
            <div style={{ marginBottom: "2rem" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={toggleBride}>
                    <p style={{ margin: 0 }}>신부측 연락하기</p>
                    <img
                        style={{ width: "15px", height: "10px", marginLeft: "7px" }}
                        src={isBrideOpen ? foldBtn : moreBtn}
                        alt={isBrideOpen ? "Fold Button" : "More Button"}
                    />
                </div>
                <Collapse isOpen={isBrideOpen}>
                    <div style={{ fontSize: "14px", marginTop: "10px" }}>
                        {renderCallInfoList(brideCallInfoList)}
                    </div>
                </Collapse>
            </div>
            </MotionWrapper>
        </div>
    );
};

export default CallInfo;
