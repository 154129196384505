import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const LeeGyeomMil = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "은철 ♥ 겸밀 결혼합니다.";
    const openDesc = " 25년 2월 22일 오후 1시 20분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/51/thumb.jpg";

    const placeTelNum = "032-225-2000";

    const forBus = "인천축구전용경기장 하차 : 인천e음22,14,26\n도원역 하차 : 15,22,26,506";
    const forCar = "제1경인고속도로 도화IC → 도화초교 사거리→ 제물포 방면\n(11시 방향) 좌회전 후 직진 → 숭의 시장 사거리 \n→ 숭의로타리 방면 죄회전 → 330m직진 → 우회전 \n→ 우측 인천 축구 전용 경기장 내 → 아레나파크 웨딩";
    const forParking = "① 아레나파크웨딩 P1 주차장 이용시 지하 1층\n② 홈플러스 물류입고장 입구 P2 주차장 이용시 지하 2층"
    const forSubway = "① 1호선 도원역 1번 출구 (도보 5분)\n② 도원역 지하 연결통로 직진";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "염은철",
            bank: "국민은행",
            accountNumber : "656501-01-530227",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FRSFopMjd"
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이겸밀",
            bank: "국민은행",
            accountNumber : "223301-04-431967",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8inheVo"
        },
        {
            role: "신부 모",
            name: "홍정자",
            bank: "기업은행",
            accountNumber : "494-006468-01-017",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FRcoDjydp"
        }
    ]

    
    const msg1 = "함박 눈 고운 겨울 날, \n저희 두 사람이\n서로의 마음에 깊은 사랑의 언약을 하려 합니다.";
    const msg2 = "저희 둘 함께하는 열 번째 새로운 계절의 시작과\n앞으로 함께 나아갈 수 많은 계절.";
    const msg3 = "귀한 걸음 하시어 축복해 주시면\n더없는 기쁨으로 간직하겠습니다."

    const lng = 126.643508202679;
    const lat = 37.4655868339819;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "응기억나지",
            brideAnswer: "엊그제같아"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무떨리네",
            brideAnswer: "진짜하나봐"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "정말사랑해",
            brideAnswer: "나도사랑행"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "내가잘할게",
            brideAnswer: "저녁해줄게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "우리잘살자",
            brideAnswer: "오래오래❤️"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.groomNameEn)} groomNameEn={getFirstNameEn(weddingData.brideNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <Dday_Spring brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={4} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forCar={forCar} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default LeeGyeomMil;