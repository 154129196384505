import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Info_Spring2 from '../component/Spring/Info_Spring2';
import ParentInfo from '../component/Info/ParentInfo';


const SongHyunJu = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김영민❤️송현주 결혼합니다.";
    const openDesc = "2025년 3월 29일 오후 12시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/109/thumb.jpg";

    const placeTelNum = "052-279-2020";

    const lng = 129.3480026536359;
    const lat = 35.561421912807454;

    const forBus = "신복R 104, 114, 411\n공업탑R 106, 107, 411\n시외버스터미널 107, 127\n기타 103, 123, 132 "
    const forTrain2 = "KTX 울산역에서 5003 리무진버스 탑승 -> 학성공원 \n하차 후 울산시내버스 106, 107 환승\n\nKTX 울산역에서 5002 리무진버스 탑승 -> 학성초등\n학교 하차 후 울산시내버스 환승 또는 도보 10분"
    const forCar ="[ 경부고속도로 ]\n울산JC -> 울산IC -> 신복R -> 좌회전 -> 경주 / \n교육청 방면 -> 고가도로 동덕아파트 앞. 울산 MBC \n방면 우회전 -> MBC 사거리 좌회전 복산사거리, \n학성교 방면 우회전 -> 반구사거리, 종합운동장 방면 \n좌회전 -> 약 680m 직진 \n\n[ 부산 울산고속도로 ]\n청량IC -> 두왕사거리, 시청 방면 좌회전 -> 공업탑R \n우회전 -> 터미널 사거리, 학교 방면 좌회전 -> \n반구사거리,종합운동장 방면 우측방향 \n-> 약 725m 직진"
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EFD140
    // #EB9217
    // ff8faf
    // #E36402
    // #79A4AE
    // #88abd1
    const conceptStyle = {
        // spring 블루B
        concept: "SPRING",
        titleColor: "#82bcff",
        pointColor: "#82bcff",
        btnBgColor: "#c0e0ff",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#f3faff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor",
        baseFontColor: "#000000"
    }

    const msg1 = "매년 3월 29일이 되면,\n저희는 그날을 기념하며 사진첩을 꺼내\n인생에서 가장 행복했던 기억을 떠올릴 것입니다.";
    const msg2 = "그 때, 그 사진 속에, \n저희와 함께 웃고 있는 여러분이 있었으면 좋겠습니다."

    const parents = [
        {groom : "김원섭 · 김둘레의 둘째"},
        {bride : "송성우 · 김지현의 둘째"}
    ]

    const parentInfo = {
        groom: {
            dad: { name: "김원섭", isDeceased: false },
            mom: { name: "김둘레", isDeceased: false },
            role: "둘째",
        },
        bride: {
            dad: { name: "송성우", isDeceased: false },
            mom: { name: "김지현", isDeceased: false },
            role: "둘째",
        }
    };

    const groomAccountList = [
        {
            role: "신랑",
            name: "김영민",
            bank: "경남은행",
            accountNumber : "570210428483",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej9QBlZsM"
        }
        ,{
            role: "신랑 부",
            name: "김원섭",
            bank: "부산은행",
            accountNumber : "059-02-037147-9",
            isKakao : false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "김영숙",
        //     bank: "농협은행",
        //     accountNumber : "310-02-171775",
        //     isKakao :false
        // }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "송현주",
            bank: "카카오뱅크",
            accountNumber : "3333246215785",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8GV8od6"
        }
        ,{
            role: "신부 부",
            name: "송성우",
            bank: "케이뱅크",
            accountNumber : "100-157-055198",
            isKakao : false
        }
        // ,{
        //     role: "신부 모",
        //     name: "마정선",
        //     bank: "신한은행",
        //     accountNumber : "110-122-146049",
        //     isKakao : false
        // }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김영웅",
            call: "010-4789-8086"
        },
        {
            role: "신랑 부",
            name: "김성구",
            call: "010-3408-8016"
        },
        {
            role: "신랑 모",
            name: "김영숙",
            call: "010-3427-8086"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이수빈",
            call: "010-2765-5827"
        },
        {
            role: "신부 부",
            name: "이상근",
            call: "010-4492-5827"
        },
        {
            role: "신부 모",
            name: "마정선",
            call: "010-5324-5827"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "또 보고싶어",
            brideAnswer: "좀 귀엽네?"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "떨리고 설레",
            brideAnswer: "황홀할거야"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "행복합시다",
            brideAnswer: "누나만 믿어"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "와이프라니",
            brideAnswer: "남편이라니"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: " 많이많이♥"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#82bcff"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter titleFontSize={"18px"} fontSize={"14px"} title={"영민과 현주 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList titleFontSize={"15px"} fontSize={"13px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring titleFontSize={"18px"} fontSize={"14px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc titleFontSize={"16px"} fontSize={"13px"} forTrain2={forTrain2} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default SongHyunJu;