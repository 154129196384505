import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import musicFile from '../asset/music/winter.mp3'

const RohSoHyun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "한승훈❤️노소현 결혼합니다.";
    const openDesc = "2025년 4월 20일 일요일 오후 3시 30분\n더컨벤션신사 4층 그랜드볼룸";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/101/thumb1.jpg";

    const placeTelNum = "02 6081 5000";

    const lng =  127.019091004701;
    const lat = 37.5195659036492;

    const forBus = "240, 4312, 9404, 6009, 145, 148, 441, 542, 4212, \n3030, 강남08, 서초03"
    const forCar = "네비게이션 주소검색 \n신주소 : 강남구 강남대로 652 신사스퀘어 \n 구주소 : 강남구 신사동 504-11 \n\n * 주차공간이 협소하오니 되도록 대중교통을 \n이용해주시길 바랍니다."
    const forSubway = "3호선, 신분당선(신사역) 6번 출구에서 300M 도보 5분"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #8CA058
    // #A9B6A0
    // #AAB456
    const conceptStyle = {
        // 빈티지 연핑크
        concept: "SPRING",
        titleColor: "#edacb1",
        pointColor: "#edacb1",
        btnBgColor: "#edacb1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "추운 겨울,\n서로에게 봄이 되어 준 두 사람이\n함께 맞는 네 번째 봄,\n새로운 시작을 약속합니다.";
    const msg2 = "소중한 날, 저희의 새로운 시작을\n축복해 주시면 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "한승훈",
            bank: "새마을금고",
            accountNumber : "9003-2584-6929-5",
            isKakao :false
        }
        ,{
            role: "신랑 부",
            name: "한동구",
            bank: "카카오뱅크",
            accountNumber : "3333-01-4357507",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "박옥자",
            bank: "농협은행",
            accountNumber : "111-12-225418",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "노소현",
            bank: "기업은행",
            accountNumber : "959-004158-01-017",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "노우형",
            bank: "우리은행",
            accountNumber : "457-08-240411",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "한순흠",
            bank: "새마을금고",
            accountNumber : "2346 -09-0180519",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이현우",
            call: "010-9413-9081"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "박민수",
        //     call: "010-3651-8953"
        // }
        ,{
            role: "신랑 모",
            name: "조주연",
            call: "010-5138-9080"
        }
    ]

    
    const brideCallInfoList = [
        {
            role: "신부",
            name: "조유민",
            call: "010-2750-8809"
        },
        {
            role: "신부 부",
            name: "조성택",
            call: "010-5256-1110"
        },
        {
            role: "신부 모",
            name: "한현자",
            call: "010-6276-0111"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "꽤당돌하네",
            brideAnswer: "걍웃긴오빠"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "올게왔구나",
            brideAnswer: "떨리진않아"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보자",
            brideAnswer: "항상고마워"
        },
        {
            question : "서로에게 바라는 점은?",
            groomAnswer: "스팸구워줘",
            brideAnswer: "네가해먹어"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나먼저간다",
            brideAnswer: "나도가용"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#edacb1"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"소중한 분들을 초대합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forBus={forBus} forCar={forCar} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default RohSoHyun;