import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';


const SoJiHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "박기웅❤소지현 결혼합니다";
    const openDesc = "월드컵컨벤션 그랜드볼룸홀(2F)\n4월 26일 오후 3시 40분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/94/thumb.jpg";


    const lng = 126.897273034482;
    const lat = 37.5682884450841;

    const placeTelNum = "02-3152-7700";

    const forCar = "[네비게이션 검색 시 '상암월드컵컨벤션'입력]\n\n- 월드컵경기장 서문 진입 후 저측 1,2 주차장 이용\n- 주차 접수대 사전등록 후 출차(90분 무료)\n- 경기·행사일 서측 주차장 만차 시 주차요원의 안내에 \n따라 난지천 공원, 평화의공원 이용(3시간 무료)\n- 네이버에 '상암월드컵컨벤션'으로 검색하시면 정확한 \n주차장 안내를 받을 수 있습니다.\n- 홈플러스 주차장은 무료주차가 불가합니다.\n\n행사 또는 경기가 있는 경우, 대중교통을 이용하시기 \n바랍니다.";
    const forBus = "[월드컵경기장 서측. 문화비축기지 정류장]\n\n  간선 - 571, 710, 760\n지선 - 7019. 7715, 8777\n광역 - 9711";
    const forSubway = "[6호선 월드컵경기장역 2번 출구 도보3분(200m)]\n\n- 월드컵경기장역 2번 출구에 경기장 서측(W)에 \n안내배너가 있습니다.\n- 환승역: 2호선(합정), 3호선(약수,연신내), \n4호선(삼각지), 5호선(공덕), 경의중앙선·공항철도(DMC)";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    // const conceptStyle = {
    //     // spring 핑크B
    //     concept: "SPRING",
    //     titleColor: "#ff8faf",
    //     pointColor: "#ff8faf",
    //     btnBgColor: "#ffcfe1",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#fff5f9",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }

    const msg1 = "풀들이 향기를 가득 머금은 계절,\n사랑으로 하나되어\n한 길을 걸어가고자 합니다.";
    const msg2 = "그 시작의 첫걸음에 함께 하시어\n축복으로 가득 채워주십시오.";
    const msg3 = "더 없는 기쁨으로\n깊이 간직하며 살겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "박기웅",
            bank: "카카오뱅크",
            accountNumber : "3333-06-7959416",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej89IS82v"
        },
        {
            role: "신랑 부",
            name: "박원용",
            bank: "농협은행",
            accountNumber : "302-1934-4591-51",
            isKakao : false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "이병순",
        //     bank: "농협은행",
        //     accountNumber : "453047-56-045524",
        //     isKakao :false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "소지현",
            bank: "카카오뱅크",
            accountNumber : "3333-04-9358191",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8PMMOW5"
        },
        {
            role: "신부 부",
            name: "소순섭",
            bank: "우체국",
            accountNumber : "010033-02-272691",
            isKakao : false
        }
        // ,{
        //     role: "신부 모",
        //     name: "유선희",
        //     bank: "농협은행",
        //     accountNumber : "461015-52-066612",
        //     isKakao : false
        // }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이영인",
            call: "010-4789-2824"
        },
        {
            role: "신랑 부",
            name: "이청현",
            call: "010-4412-2824"
        },
        {
            role: "신랑 모",
            name: "이병순",
            call: "010-5437-2824"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이보은",
            call: "010-6487-7597"
        },
        {
            role: "신부 부",
            name: "이성열",
            call: "010-5402-7597"
        },
        {
            role: "신부 모",
            name: "유선희",
            call: "010-5558-7597"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫눈에 반함",
            brideAnswer: "너무 떨렸어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "난 기대돼ෆ"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "넌 이제 내꺼",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "결혼을 앞두고 부모님들께 드리고싶은 말은?",
            groomAnswer: "감사합니다",
            brideAnswer: "사랑해요ෆ"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이ෆ"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#edacb1"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default SoJiHyeon;