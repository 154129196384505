import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const KangSuHyun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이건호 ♥️ 강수현";
    const openDesc = "2025.4.19 토요일 오전 11시30분\nBW 웨딩 보네르 홀 4층";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/113/thumb.jpeg";

    const lng =  128.415255422996;
    const lat =  36.1571069746721;

    const placeTelNum = "054-473-5000";

    const forCar = "📌 대구에서 차량을 이용하는 경우 가산 IC를 \n이용하는 것이 더 편리합니다.\n\n📌 서울에서 차량을 이용하는 경우 구미 IC를 \n이용하는 것이 더 편리합니다.";
    const forBus = "옥계대백타운 입구 (양포파출소 앞) 정류장 하차 후 \n도보 15분 \n90, 90-2, 380-1, 404, 192, 890, 891, 891-1";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "2019년 가을, 작은 인연으로 시작되어\n5년의 사계절을 함께 보냈습니다.";
    const msg2 = "이유없이 사랑하고\n서로를 위하는 마음이 한결같은\n두 사람이 평생을 함께 하려합니다."
    const msg3 = "부부로서의 결실을 맺는 그날\n귀한 걸음내시어\n자리를 빛내주시면 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "이건호",
            bank: "농협은행",
            accountNumber : "302-0569-0228-61",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8GW3Xy3"
        },
        {
            role: "신랑 부",
            name: "이재식",
            bank: "농협은행",
            accountNumber : "302-0262-3638-41",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김경희",
            bank: "농협은행",
            accountNumber : "302-0513-4437-81",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "강수현",
            bank: "국민은행",
            accountNumber : "613202-04-228046",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9TwEw5s"
        }
        // ,{
        //     role: "신부 부",
        //     name: "이홍강",
        //     bank: "농협은행",
        //     accountNumber : "211042-56-058778",
        //     isKakao : false
        // }
        // ,{
        //     role: "신부 모",
        //     name: "박윤옥",
        //     bank: "카카오뱅크",
        //     accountNumber : "3333-27-1273326",
        //     isKakao : false
        // }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "잊지못하지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려",
            brideAnswer: "실감이 안나"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "엄청 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "행복 그잡채",
            brideAnswer: "늘 지금처럼"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "아자아자!",
            brideAnswer: "화이팅!"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "문병관",
            call: "01056503499"
        }
        ,
        {
            role: "신랑 부",
            name: "문사권",
            call: "01066360179"
        },
        {
            role: "신랑 모",
            name: "황혜영",
            call: "01065360179"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이하린",
            call: "01021867191"
        }
        ,{
            role: "신부 부",
            name: "이홍강",
            call: "01064699689"
        }
        ,{
            role: "신부 모",
            name: "박윤옥",
            call: "01057734553"
        }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#000000"} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ecf5fc"} bottom={"25%"} bottom2={"15%"} dateBottom={"1%"} top={"0.5%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KangSuHyun;