import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import musicFile from '../asset/music/summer.mp3'


const JungBoKyung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이동권❤정보경 결혼합니다.";
    const openDesc = "2025년 3월 22일 토요일";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/85/thumb.jpg";

    const placeTelNum = "053-602-7114";


    const lng = 128.659657115395;
    const lat = 35.8743956534157;

    const forBus = "518, 651(호텔정문 승하차), 708, 814, 849, 849-1, \n급행5";
    const forSubway = "지하철 1호선 아양교역 하차 → 택시\n택시 - 거리 3km 소요시간 약 10분 (요금 : 5,000원 정도)\n\n지하철 1호선 아양교역 하차 → 버스	\n버스 환승 - 814 거리 3km 소요시간 약 20분\n\n지하철 2호선 만촌역 하차 → 택시	\n거리 5km 소요시간 약 10분 (요금 : 6,500원 정도)\n\n지하철 2호선 만촌역 하차 → 버스	\n버스 환승 - 849 거리 5km 소요시간 약 30분";
    const forTrain = "동대구 환승센터에서 오실 때\n\n택시 - 거리 4km 소요시간 약 15분 \n(요금 : 6,000원 정도)\n\n버스	- 708, 814(망우공원에서 하차), \n651(호텔인터불고에서 하차)\n거리 5km 소요시간 약 25분";
    const forFlight = "대구 국제공항에서 오실 때\n택시	거리 5km 소요시간 약 15분 \n(요금 : 6,500원 정도)\n\n버스	급행1 / 팔공1→아양교역 하차(환승)→814 \n(망우공원에서 하차)\n거리 8km 소요시간 약 40분"


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }



    const msg1 = "새로운 내일을 꿈꾸게 해주는 사람을 만나\n결혼을 약속하게 되었습니다.";
    const msg2 = "서로를 향한 사랑을 맹세하는 이 순간에\n귀한 걸음 하셔서 축하해주신다면\n행복한 그날을 간직하며 살겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "이동권",
            bank: "하나은행",
            accountNumber : "802-910210-83707",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7jSwlkc"
        },
        {
            role: "신랑 부",
            name: "이종식",
            bank: "카카오뱅크",
            accountNumber : "3333-13-4394359",
            isKakao : false
        },

        {
            role: "신랑 모",
            name: "장경순",
            bank: "농협은행",
            accountNumber : "750-02-076306",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "정보경",
            bank: "기업은행",
            accountNumber : "370-062978-01-011",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8M9ZTIi"
        },
        {
            role: "신부 부",
            name: "정순탁",
            bank: "IM뱅크",
            accountNumber : "015-08-166313",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "손기조",
            bank: "IM뱅크",
            accountNumber : "177-08-019728-1",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이영인",
            call: "010-4789-2824"
        },
        {
            role: "신랑 부",
            name: "이청현",
            call: "010-4412-2824"
        },
        {
            role: "신랑 모",
            name: "이병순",
            call: "010-5437-2824"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이보은",
            call: "010-6487-7597"
        },
        {
            role: "신부 부",
            name: "이성열",
            call: "010-5402-7597"
        },
        {
            role: "신부 모",
            name: "유선희",
            call: "010-5558-7597"
        }
    ]

    const interviewContent = [
        {
            question : "결혼을 앞둔 소감은?",
            groomAnswer: "후련할 거 같아!",
            brideAnswer: "신혼여행❤"
        },
        {
            question : "우리의 결혼에서 가장 중요한 가치는?",
            groomAnswer: "peace",
            brideAnswer: "fidelity"
        },
        {
            question : "우리의 인생의 목표는?",
            groomAnswer: "건강과 장수",
            brideAnswer: "카르페디엠"
        },
        {
            question : "10년, 20년 후 우리의 모습은 어떤 모습일까?",
            groomAnswer: "변치 않을거야.",
            brideAnswer: "어른이 될거야."
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑해 영원히.",
            brideAnswer: "덕분에 행복해."
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring title={"서로에게 전하는 우리 이야기"} interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forTrain={forTrain} forFlight={forFlight} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default JungBoKyung;