import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';
import Information from '../component/Information';
import Info_Spring2 from '../component/Spring/Info_Spring2';

const LeeSoJeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김진현♥이소정 결혼합니다.";
    const openDesc = "2025.03.22 2:00PM 그랜드모먼트";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/122/thumb.jpg";

    const lng = 129.100265647918;
    const lat = 35.1455934420333;

    const placeTelNum = "051-922-2210";

    const forCar = "본 건물 B5층, B1층, 지상주차장, 타워주차장 이용";
    const forBus = "대남교차로 & 남천역 하차 후 셔틀버스 이용\n20, 39, 40, 41, 42, 51, 83-1, 83, 108, 131, 155, \n1001, 1003\n동원 보라 아파트 하차 ▶ 도보 5분 소요\n38, 583";
    const forShuttleBus = "남천역 4번 출구 230m 앞 더샵 입구 (103동 근처)\n25인승 셔틀버스 15~20분 간격 운행"
    const forSubway = "2호선 남천역 4번 출구 ▶ 셔틀버스 이용";
    const forTrain = "부산역 하차시 지하철 1호선(노포방면) ▶ 서면역 2호선 \n환승(장산방면)\n남천역 하차 4번 출구 ▶ 셔틀버스 이용"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    // #2B513D
    // #31733B
    //rgb(14, 131, 108)
    const conceptStyle = {
        // summer 올리브
        concept: "SUMMER",
        titleColor: "#0e836c",
        // pointColor: "#31733B",
        pointColor: "#0e836c",
        btnBgColor: "#0e836c",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const msg1 = "따스한 봄날\n저희 두 사람 결혼합니다";
    const msg2 = "꽃보다 아름답게, 햇살보다 따뜻하게\n살아가겠습니다.\n소중한 시간 내주셔서\n함께 축복해 주시면 감사하겠습니다."

    const parents = [
        {groom : "김진수 · 심복덕의 아들"},
        {bride : "이태영 · 이정숙의 딸"}
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "김진현",
            bank: "카카오뱅크",
            accountNumber : "3333-22-6125667",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Fbxa2tRRz"
        },
        {
            role: "신랑 부",
            name: "김진수",
            bank: "농협은행",
            accountNumber : "275-12-113583",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "심복덕",
            bank: "농협은행",
            accountNumber : "352-1184-5608-93",
            isKakao : false
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "이소정",
            bank: "국민은행",
            accountNumber : "566301-01-313057",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FOy6igXhF"
        }
        ,{
            role: "신부 부",
            name: "이태영",
            bank: "신한은행",
            accountNumber : "110-469-035722",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이정숙",
            bank: "농협은행",
            accountNumber : "947-02-208490",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "잊지못하지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려",
            brideAnswer: "실감이 안나"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "엄청 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "행복 그잡채",
            brideAnswer: "늘 지금처럼"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "아자아자!",
            brideAnswer: "화이팅!"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김진현",
            call: "010-2845-2483"
        }
        ,
        {
            role: "신랑 부",
            name: "김진수",
            call: "010-6671-1587"
        },
        {
            role: "신랑 모",
            name: "심복덕",
            call: " 010-4412-5899"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이소정",
            call: "010-4007-2518"
        }
        ,{
            role: "신부 부",
            name: "이태영",
            call: "010-9335-8488"
        }
        ,{
            role: "신부 모",
            name: "이정숙",
            call: "010-3750-2375"
        }
    ]

    const informationList =[
        {
            title : "식사 안내",
            content : "• 식사시간\nPM 14:00~16:00 뷔페 이용 가능\n\n• 스테이크 교환권 안내\n교환권을 스테이크 코너에 제시 해 주시면\n'안심 스테이크'가 제공됩니다.\n\n• 어린이 동반 하객\n미취학 아동은 식권없이 입장 가능합니다.\n(식권은 8세 이상 부터 수령)"
        }
        ,{
            title: "셔틀버스 안내",
            content : "그랜드모먼트 셔틀버스를 운행하고 있습니다.\n(15~20분 간격으로 운행)\n• 오실때\n지하철 2호선 남천역 4번출구 기준, \n전방 250m 남천더샵아파트 103동 근처\n\n• 가실때\n그랜드모먼트(예식홀) B4층 기준, \n좌측 셔틀버스 정류장\n\n* 식전 차량이 많아 진입 시\n교통혼란이 있습니다. 참고 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#405200"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#ffffff"} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} bottom={"86%"} bottom2={"77%"} dateBottom={"1%"} top={"1%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"INVITATION\n초대합니다"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <Information width={"320px"} height={"210px"} divHeight={"340px"} conceptStyle={conceptStyle} informationList={informationList} />
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forCar={forCar} forBus={forBus} forShuttleBus={forShuttleBus} forTrain={forTrain} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeSoJeong;