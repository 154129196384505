import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Info_Spring2 from '../component/Spring/Info_Spring2';


const KangJeongHui = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "박근혁❤강정희 결혼합니다.";
    const openDesc = "25년3월1일 토요일 오후 12시10분\n더 파티움 안양 7층 라포레홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/98/thumb.jpg";

    const placeTelNum = "031-731-5000";

    const lng = 126.964662720477;
    const lat = 37.3960785735488;

    const forBus = "'평촌역' 하차\n(일반버스) 1, 6, 22, 52, 52-1, 83 \n(마을버스) 2-1, 5, 5-1, 5-5, 6, 6-1, 7, 8, 10-1";
    const forSubway = "4호선 '평촌역' 3번출구 횡단보도 맞은편";
    const forCar = "네비게이션 - '더파티움 안양' 또는 '시민대로311' 입력\n\n(제1주차장) 더파티움 안양 본건물 지하주차장 \n- 동안구 시민대로311 (관양동1746)\n(제2주차장) 네온테크 주차장 \n- 동안구 부림로146 (관양동 1745-3)\n(제3주차장) 이마트 평촌점 주차장 \n- 동안구 시민대로300 (관양동 1608)\n(제4주차장) 평촌 칼라힐 주차빌딩 (하이파킹 주차타워) \n- 동안구 시민대로312 (평촌동897)"
    const desc = "경기도 안양시 동안구 시민대로311 금강스마트빌딩 \n5층~7층 더 파티움 안양"


    const parents = [
        {groom : "박민수 · 전희영의 아들"},
        {bride : "김영덕 · 김정옥의 딸"}
    ]

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // 딥로즈 핑크 : #BC5078
    // 빈티지 로즈 : #D77599
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "제일 친한 친구에서 애인, 이제는 부부";
    const msg2 = "10년의 세월을 돌고돌아 운명으로 만난 저희가\n이젠 친구가 아닌 배우자로 함께 길을 걸어가려합니다.";
    const msg3 = "가장 행복하고 아름다운 순간, 소중한 분들을 초대합니다.\n부디 귀한 시간 내주시어 함께 축복 해주시면 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "박근혁",
            bank: "우리은행",
            accountNumber : "1002-456-013421",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej82tK8NX"
        }
        ,{
            role: "신랑 부",
            name: "박민수",
            bank: "카카오뱅크",
            accountNumber : "333306-3169347",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FZWCCt0M8"
        }
        ,{
            role: "신랑 모",
            name: "전희영",
            bank: "하나은행",
            accountNumber : "574-910201-22007",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FIPZsmqOm"
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "강정희",
            bank: "우리은행",
            accountNumber : "1002-653-540370",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FC50oB91H"
        }
        ,{
            role: "신부 부",
            name: "김영덕",
            bank: "농협은행",
            accountNumber : "3130-6551-193168",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김정옥",
            bank: "우리은행",
            accountNumber : "1002-230-346595",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "박근혁",
            call: "010-5121-2479"
        },
        {
            role: "신랑 부",
            name: "박민수",
            call: "010-3651-8953"
        },
        {
            role: "신랑 모",
            name: "전희영",
            call: "010-8342-2476"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "강정희",
            call: "010-2493-0412"
        },
        {
            role: "신부 부",
            name: "김영덕",
            call: "010-3595-5417"
        },
        {
            role: "신부 모",
            name: "김정옥",
            call: "010-6277-8074"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "꽤당돌하네",
            brideAnswer: "걍웃긴오빠"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "올게왔구나",
            brideAnswer: "떨리진않아"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보자",
            brideAnswer: "항상고마워"
        },
        {
            question : "서로에게 바라는 점은?",
            groomAnswer: "스팸구워줘",
            brideAnswer: "네가해먹어"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나먼저간다",
            brideAnswer: "나도가용"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"저희 결혼 합니다❤"} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"253px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc desc={desc} forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KangJeongHui;