import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';


const KimHyunSun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이경복♥️김현선 결혼합니다.";
    const openDesc = "2025년 4월 19일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/89/thumb.jpg";

    const placeTelNum = "031-611-1414";

    const lng = 127.08061656121312;
    const lat = 37.06184100072372;

    const forCar = "네비게이션 : '국제웨딩컨벤션' 입력\n주차장 : 국제컨벤션센터 주차장 이용 \n(국제대학교 전체 주차 시설 이용가능)"
    const forShuttleBus = "1호선 서정리역 1번 출구 <-> 셔틀버스 국제웨딩컨벤션\n서정리역 출발 : 매시 5분, 35분\n\n국제웨딩컨벤션 출발 : 매시 20분, 50분\n(예식 1시간 전부터 예식 2시간 후 까지 운행)";
    const forSubway = "1호선 서정리역 하차 <-> 셔틀버스 국제웨딩컨벤션 하차\n\nSRT 지제역 하차 -> 1호선 서정리역 하차 \n<-> 셔틀버스 국제웨딩컨벤션 하차"
    const forBus = "코오롱하늘채아파트 동문 or 북문 하차\n5-3, 7, 88A, 88B, 99"
    

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const msg1 = "봄꽃처럼 곱게,\n봄 햇살처럼 포근하게,\n서로를 귀히 여기며 살겠습니다.";
    const msg2 = "그 시작의 자리에\n귀한 걸음으로 축하하여 주시면\n더없는 기쁨으로 간직하겠습니다.";

    const parents = [
        {groom : "이상구 · 이미자의 아들"},
        {bride : "김광우 · 백명자의 딸"}
    ]
    const groomAccountList = [
        {
            role: "신랑",
            name: "이경복",
            bank: "기업은행",
            accountNumber : "186-040870-01-012",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FcpmeiBus"
        }
        ,
        {
            role: "신랑 부",
            name: "이상구",
            bank: "하나은행",
            accountNumber : "347-890056-23407",
            isKakao : false
        },

        {
            role: "신랑 모",
            name: "이미자",
            bank: "우리은행",
            accountNumber : "1002-537-485509",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김현선",
            bank: "농협은행",
            accountNumber : "352-0234-0335-23",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7zrjypM"
        }
        ,
        {
            role: "신부 부",
            name: "김광우",
            bank: "우리은행",
            accountNumber : "303-07-145998",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "백명자",
            bank: "국민은행",
            accountNumber : "207101-04-151270",
            isKakao : false
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이경복",
            call: "010-4776-9619"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "변민석",
        //     call: "010-3862-5638"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김은하",
        //     call: "010-7331-5638"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김현선",
            call: "010-7106-7028"
        }
        // ,{
        //     role: "신부 부",
        //     name: "배종진",
        //     call: "010-5430-0303"
        // },
        // {
        //     role: "신부 모",
        //     name: "정성례",
        //     call: "010-3882-3777"
        // }
    ]

    const account_explain = "";

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "눈이 예쁘네",
            brideAnswer: "오 깔끔하다"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "고지가 눈앞"
        },
        {
            question : "결혼을 결심한 이유는?",
            groomAnswer: "그냥 너라서",
            brideAnswer: "귀여워서~"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: " 말을 잘 듣자",
            brideAnswer: "행복하자잉"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "꽁냥꽁냥",
            brideAnswer: "잘 살게요❤️"
        }
    ]

    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter isFormatWeddingTime={true} width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} parents={parents} conceptStyle={conceptStyle} weddingData={weddingData} isFormatWeddingTime={true}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forSubway={forSubway} forCar={forCar} forShuttleBus={forShuttleBus} forBus={forBus} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimHyunSun;