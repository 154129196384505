import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import musicFile from '../asset/music/우효-민들레.mp3';


const KangSeoHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "길동우 ♥ 강서현 결혼합니다.";
    const openDesc = "라도무스홀 3월 30일 오후 1:30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/104/thumb.jpg";
    
    const lng =  127.333649072247;
    const lat = 36.333056192309;

    const placeTelNum = "042-823-5220";

    const forSubway = "유성온천역 6번출구 → 106번, 706번 중 승차 \n→ 106번 흥도초하차, 706번 등기소/아이파크시티 하차 \n→ 목원대사거리 우측방향 500M 도보";
    const forRentBus = "종합철물(전남 목포시 양을로 76)앞에서 8:30AM 출발 → \n 8:45AM 하당 국민은행 앞 → 8:55AM 목포중앙병원 \n건너편 신호등앞";
    const forCar = "<유성IC>\n유성IC삼거리에서 '공주,계룡산' 방면 좌회전 후 직진 → \n'서대전,유성' 방면 좌회전 후 직진 → 구암역삼거리 좌회전 → 유성온천역사거리 우회전 → 도안고등학교 → 목원대 \n사거리 → 우회전 500M"


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #ff8faf
    // #F93682
    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#F93682",
        pointColor: "#F93682",
        btnBgColor: "#fc83b3",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "봄 햇살이 함께 했던 지난날, \n 여러 해의 봄날 처럼 \n 앞으로도 햇살같은 봄날을 함께 하려 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 저희의 봄날에 함께 \n 축복 해주시면 감사하겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "길동우",
            bank: "신한은행",
            accountNumber : "110-492-225330",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "길구현",
            bank: "IBK기업",
            accountNumber : "143-182256-02-012",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김소은",
            bank: "농협은행",
            accountNumber : "356-1081-7211-63",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "강서현",
            bank: "신한은행",
            accountNumber : "110-518-261533",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "강주석",
            bank: "새마을금고",
            accountNumber : "9002-1576-59336",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "나선화",
            bank: "새마을금고",
            accountNumber : "2804-09-0078740",
            isKakao :false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "길동우",
            call: "010-6285-0828"
        }
        ,{
            role: "신랑 부",
            name: "길구현",
            call: "010-6288-9752"
        }
        ,{
            role: "신랑 모",
            name: "김소은",
            call: "010-4613-0828"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "강서현",
            call: "010-4518-9800"
        },
        {
            role: "신부 부",
            name: "강주석",
            call: "010-7474-3400"
        },
        {
            role: "신부 모",
            name: "나선화",
            call: "010-7199-3400"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "꽤당돌하네",
            brideAnswer: "걍웃긴오빠"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "올게왔구나",
            brideAnswer: "떨리진않아"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보자",
            brideAnswer: "항상고마워"
        },
        {
            question : "서로에게 바라는 점은?",
            groomAnswer: "스팸구워줘",
            brideAnswer: "네가해먹어"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나먼저간다",
            brideAnswer: "나도가용"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forSubway={forSubway} forRentBus={forRentBus} forCar={forCar} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KangSeoHyeon;