import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';


const JoYouMin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "현우♥유민 결혼식에 초대합니다 👰🏻‍♀️🤵🏻‍♂️❤️";
    const openDesc = "3월 29일 오후 12시 그랜드힐컨벤션";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/95/thumb.jpg";

    const placeTelNum = "02-6964-7889";

    const lng = 127.066848092609;
    const lat = 37.506248899533;

    const forBus = "(간선) 143, 146, 341, 360, 363, 4401\n(지선) 2413, 3411, 3422, 4318, 4419, 917, 11-3\n(광역) 9407, 6900";
    const forParking = "그랜드힐 컨벤션 주차장 3시간 무료";
    const forShuttleBus ="지하철 2호선 삼성역 1번출구 승차(글래드호텔 앞)"


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // BC5078
    // B86D82
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#e05e8e",
        pointColor: "#e05e8e",
        btnBgColor: "#e05e8e",
        fontColor: "#000000",
        btnFontColor: "#ffffff",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor",
        baseFontColor : "#000000",
    }

    const msg1 = "함께 맞이하는 여섯 번째 봄, 저희 결혼합니다";
    const msg2 = "평생 좋은 친구로, 사랑하는 연인으로\n서로를 아껴주고 사랑하며 행복하게 살겠습니다";
    const msg3 = "저희의 설레고 풋풋한 시작의 자리에\n소중한 분들을 초대합니다"


    const groomAccountList = [
        {
            role: "신랑",
            name: "이현우",
            bank: "신한은행",
            accountNumber : "110-265-670300",
            isKakao :false
        }
        // ,{
        //     role: "신랑 부",
        //     name: "박민수",
        //     bank: "카카오뱅크",
        //     accountNumber : "333306-3169347",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "조주연",
            bank: "우리은행",
            accountNumber : "747-083807-02-101",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "조유민",
            bank: "우리은행",
            accountNumber : "1002-945-912887",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "조성택",
            bank: "기업은행",
            accountNumber : "626-001419-01-027",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "한현자",
            bank: "농협은행",
            accountNumber : "105-12-299032",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이현우",
            call: "010-9413-9081"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "박민수",
        //     call: "010-3651-8953"
        // }
        ,{
            role: "신랑 모",
            name: "조주연",
            call: "010-5138-9080"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "조유민",
            call: "010-2750-8806"
        },
        {
            role: "신부 부",
            name: "조성택",
            call: "010-5256-1110"
        },
        {
            role: "신부 모",
            name: "한현자",
            call: "010-6276-0111"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "꽤당돌하네",
            brideAnswer: "걍웃긴오빠"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "올게왔구나",
            brideAnswer: "떨리진않아"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보자",
            brideAnswer: "항상고마워"
        },
        {
            question : "서로에게 바라는 점은?",
            groomAnswer: "스팸구워줘",
            brideAnswer: "네가해먹어"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나먼저간다",
            brideAnswer: "나도가용"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring  groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date  color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring fontSize={"15px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter titleFontSize={"18px"} fontSize={"15px"}  title={"우리, 결혼합니다"} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList titleFontSize={"15px"} fontSize={"14px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring titleFontSize={"18px"} fontSize={"14px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                        <Location_Desc titleFontSize={"16px"} fontSize={"13px"} forShuttleBus={forShuttleBus} forParking={forParking} forBus={forBus} conceptStyle={conceptStyle}/>
                        {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default JoYouMin;