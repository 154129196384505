import { useParams } from 'react-router-dom';
import Spring from './page/Spring';
import GuestBookCake from './page/GuestBookCake';
import Admin from './page/Admin';
import Summer from './page/Summer';
import Fall from './page/Fall';
import Winter from './page/Winter';
import SunkJin from './page/SunkJin';
import Loading from './component/Loading';
import JeongYunJi from './page/JeongYunJi';
import UmMinYoung from './page/UmMinYoung';
import KimKaYoung from './page/KimKaYoung';
import KimSol from './page/KimSol';
import ShinSoBin from './page/ShinSoBin';
import ParkSeoYoung from './page/ParkSeoYoung';
import KimMinJi from './page/KimMinJi';
import JeongSeonYeong from './page/JeongSeonYeong';
import ShinHyeYeon from './page/ShinHyeYeon';
import GilTaeEun from './page/GilTaeEun';
import YoonYuJin from './page/YoonYuJin';
import ParkNaYoung from './page/ParkNaYoung';
import JangSooYeon from './page/JangSooYeon';
import SongJooHee from './page/SongJooHee';
import YooSeulKi from './page/YooSeulKi';
import LeeJiEun from './page/LeeJiEun';
import KimYoonJi from './page/KimYoonJi';
import KoEunYoung from './page/KoEunYoung';
import ParkHoJeong from './page/ParkHoJeong';
import LeeSongI from './page/LeeSongI';
import LeeGaHyang from './page/LeeGaHyang';
import AnHyeJin from './page/AnHyeJin';
import KimJeongA from './page/KimJeongA';
import GoHuiSong from './page/GoHuiSong';
import MinHaJin from './page/MinHaJin';
import KimHyeonA from './page/KimHyeonA';
import KimKaYoung2 from './page/KimKaYoung2';
import KimTaeYoung from './page/KimTaeYoung';
import ChoiJinYoung from './page/ChoiJinYoung';
import BaekSeungHee from './page/BaekSeungHee';
import ParkAeRi from './page/ParkAeRi';
import KimSoYoung from './page/KimSoYoung';
import AnMinJu from './page/AnMinJu';
import LeeYeRim from './page/LeeYeRim';
import KwakAReum from './page/KwakAReum';
import KimAReum from './page/KimAReum';
import SeongHeeGee from './page/SeongHeeGee';
import KimYoonJi2 from './page/KimYoonJi2';
import HwangJiHyeon from './page/HwangJiHyeon';
import SeoYuJin from './page/SeoYuJin';
import SeoHanNa from './page/SeoHanNa';
import KimSeungHee from './page/KimSeungHee';
import HongJuHa from './page/HongJuHa';
import BangMinJi from './page/BangMinJi';
import LeeUSeon from './page/LeeUSeon';
import LeeGyeomMil from './page/LeeGyeomMil';
import LeeSuYeon from './page/LeeSuYeon';
import LimSuBin from './page/LimSuBin';
import SimHyeYeon from './page/SimHyeYeon';
import LeeSuYeon2 from './page/LeeSuYeon2';
import LimSuBin2 from './page/LimSuBin2';
import KimMinJi2 from './page/KimMinJi2';
import BaekJiWon from './page/BaekJiWon';
import ParkMiYeon from './page/ParkMiYeon';
import KimHyeJu from './page/KimHyeJu';
import KimSeolHwa from './page/KimSeolHwa';
import KimSong from './page/KimSong';
import HwangHwaYeon from './page/HwangHwaYeon';
import HwangYeJi from './page/HwangYeJi';
import SonDaSol from './page/SonDaSol';
import ShinYouJung from './page/ShinYouJung';
import YooYeonJin from './page/YooYeonJin';
import HwangBoJun from './page/HwangBoJun';
import ChoiGaYoung from './page/ChoiGaYoung';
import KangHyeRim from './page/KangHyeRim';
import LeeYeSa from './page/LeeYeSa';
import KimNaRi from './page/KimNaRi';
import LeeYeaJin from './page/LeeYeaJin';
import SeoMinJeong from './page/SeoMinJeong';
import EoSeYoung from './page/EoSeYoung';
import KimSeoA from './page/KimSeoA';
import KimBoHyeon from './page/KimBoHyeon';
import LeeSooJin from './page/LeeSooJin';
import KimMinGyeong from './page/KimMinGyeong';
import LeeJiYeon from './page/LeeJiYeon';
import ImDaSeul from './page/ImDaSeul';
import LimSoHee from './page/LimSoHee';
import KimDooYi from './page/KimDooYi';
import LeeBoEun from './page/LeeBoEun';
import JungBoKyung from './page/JungBoKyung';
import SimYeonJu from './page/SimYeonJu';
import BaeMinJi from './page/BaeMinJi';
import ShinInGyeong from './page/ShinInGyeong';
import KimHyunSun from './page/KimHyunSun';
import LeeHyeMi from './page/LeeHyeMi';
import LeeHaRin from './page/LeeHaRin';
import SongEunJin from './page/SongEunJin';
import SoJiHyeon from './page/SoJiHyeon';
import ChoHyeRin from './page/ChoHyeRin';
import HwangJiHye from './page/HwangJiHye';
import KangJeongHui from './page/KangJeongHui';
import OhChaeYeon from './page/OhChaeYeon';
import YangHyeJin from './page/YangHyeJin';
import JoYouMin from './page/JoYouMin';
import RohSoHyun from './page/RohSoHyun';
import KimJinSook from './page/KimJinSook';
import SungSangHee from './page/SungSangHee';
import KangSeoHyeon from './page/\bKangSeoHyeon';
import HeoJin from './page/HeoJin';
import ParkJiSeong from './page/ParkJiSeong';
import LeeDaSeul from './page/LeeDaSeul';
import KangSuHyun from './page/KangSuHyun';
import LeeHyunJoo from './page/LeeHyunJoo';
import KimDaEun from './page/KimDaEun';
import ParkSoYul from './page/ParkSoYul';
import LeeSuBin from './page/LeeSuBin';
import SongHyunJu from './page/SongHyunJu';
import ChoiJiHyeon from './page/ChoiJiHyeon';
import LeeSetByeul from './page/LeeSetByeul';
import OhAhYoung from './page/OhAhYoung';
import YoonHyeJin from './page/YoonHyeJin';
import ShinHyunJeong from './page/ShinHyunJeong';
import ParkMiDeum from './page/ParkMiDeum';
import LeeSoJeong from './page/LeeSoJeong';
import SeokJeonWon from './page/SeokJeonWon';

// 각 mainPage에 해당하는 컴포넌트를 설정
const pageComponents = {
    'sample_spring': Spring,
    'sample_summer': Summer,
    'sample_fall' : Fall,
    'sample_winter' : Winter,
    'soohoonlove': SunkJin,
    'wedding250419': JeongYunJi,
    '20250216mysion': UmMinYoung,
    'kyjh0309': KimKaYoung,
    'jinwooandsol' : KimSol,
    'jwsb250308': ShinSoBin,
    'iusy250301' : ParkSeoYoung,
    'k250329' : KimMinJi,
    'sgys250125' : JeongSeonYeong,
    'SwHy250222' : ShinHyeYeon,
    'ygte0301' : GilTaeEun,
    '250419' : YoonYuJin,
    'S2nayoungcheolS2' : ParkNaYoung,
    'S250329' : JangSooYeon,
    'hoonhee' : SongJooHee,
    '250405wedding' : YooSeulKi,
    'pjklje0309' : LeeJiEun,
    'WoneyYoonG' : KimYoonJi,
    '250222' : KoEunYoung,
    'hjxjk0405' : ParkHoJeong,
    'potato' : LeeSongI,
    '2hojin2gahyang' : LeeGaHyang,
    'hanjin0215' : AnHyeJin,
    'omss2kja' : KimJeongA,
    '01hslove' : GoHuiSong,
    'kdhj0405' : MinHaJin,
    '20250301bjha' : KimHyeonA,
    'kyjh03091' : KimKaYoung2,
    'love0323': ChoiJinYoung,
    'JSTY250412' : KimTaeYoung,
    'taeha1xnghxxe' : BaekSeungHee,
    'SHAR2504191530' : ParkAeRi,
    '20250309SSHKSY' : KimSoYoung,
    'seming0420' : AnMinJu,
    'jhyr0301' : LeeYeRim,
    'smar250412' : KwakAReum,
    'dkfma100822' : KimAReum,
    'dhg0209' : SeongHeeGee,
    'wend1' : KimYoonJi2,
    '20250322msjh' : HwangJiHyeon,
    'injaeyujin' : SeoYuJin,
    'channa89' : SeoHanNa,
    'yjsh250322' : KimSeungHee,
    '0405smjh' : HongJuHa,
    'WithJHMJ' : BangMinJi,
    'JKnSIA11052025' : LeeUSeon,
    'dmscjfruaalf2': LeeGyeomMil,
    'sysj0329' : LeeSuYeon,
    'Happy0511wedding' : LimSuBin,
    'Seungjinhyeyeon' : SimHyeYeon,
    'gyunyeon' : LeeSuYeon2,
    '20250419swsb' : LimSuBin2,
    'mjms0301' : KimMinJi2,
    'TWJW0419' : BaekJiWon,
    'mygw0412' : ParkMiYeon,
    'suyeon_hyeju' : KimHyeJu,
    'hgS2sh' : KimSeolHwa,
    'yhcks250517' : KimSong,
    'hwayeontaewoo' : HwangHwaYeon,
    'honeyji0420' : HwangYeJi,
    'juheondasol0216' : SonDaSol,
    'kkhsyj' : ShinYouJung,
    'yoobu0412' : YooYeonJin,
    'JunJiEun0330' : HwangBoJun,
    'dwgy250412' : ChoiGaYoung,
    'tachimomndad' : KangHyeRim,
    '20250426' : LeeYeSa,
    'guhyeonnarilove' : KimNaRi,
    'jchu0716' : LeeYeaJin,
    'minchan190325' : SeoMinJeong,
    'jinseopS2seyoung' : EoSeYoung,
    'momo0704': KimSeoA,
    'bhtg0511' : KimBoHyeon,
    'jhandsj' : LeeSooJin,
    'minjong0625' : KimMinGyeong,
    'jyjy0517' : LeeJiYeon,
    'DSTS250405' : ImDaSeul,
    'SHSH250426' : LimSoHee,
    'HJ_and_DY' : KimDooYi,
    'younginboeun' : LeeBoEun,
    'bleemarriage' : JungBoKyung,
    'jongseonyoenju' : SimYeonJu,
    'jongukminji' : BaeMinJi,
    '250412' : ShinInGyeong,
    'hskb250419' : KimHyunSun,
    'sjhm0412' : LeeHyeMi,
    'ninimoon' : LeeHaRin,
    'EJH0256' : SongEunJin,
    'wedding0426' : SoJiHyeon,
    'GyuheonHyerin' : ChoHyeRin,
    'gyujihye0322' : HwangJiHye,
    'w250301' : KangJeongHui,
    'thcy461223' : OhChaeYeon,
    'JHHJ0315' : YangHyeJin,
    'hwym20250329' : JoYouMin,
    'shsh250420' : RohSoHyun,
    'kmjs250426' : KimJinSook,
    'shwedding0406' : SungSangHee,
    '202503300130' : KangSeoHyeon,
    'HJwithKS250413' : HeoJin,
    'gjjs0323' : ParkJiSeong,
    'cutty0201' : LeeDaSeul,
    'gunholovesuhyun' : KangSuHyun,
    '2025041911' : LeeHyunJoo,
    'jandd0322' : KimDaEun,
    'simosoyulS2' : ParkSoYul,
    'yusb20250315' : LeeSuBin,
    '250329ymhj_love' : SongHyunJu,
    'qqq111' : ChoiJiHyeon,
    'jssb250426' : LeeSetByeul,
    'JoonpumAhyoung' : OhAhYoung,
    'hyejinsung0517' : YoonHyeJin,
    'hshj250413' : ShinHyunJeong,
    'fix250420' : ParkMiDeum,
    'jhsj250322' : LeeSoJeong,
    'JeonwonRena0406' : SeokJeonWon
};

function DynamicPage() {
    const { mainPage, subPage } = useParams();
    const Component = pageComponents[mainPage];  // mainPage에 해당하는 컴포넌트를 선택

    if (!Component) return <Loading/>;

    // subPage가 'guest'일 때 GuestBookCake 컴포넌트 렌더링
    if (subPage === 'guest') return <GuestBookCake />;
    
    // subPage가 'admin'일 때 Admin 컴포넌트 렌더링
    if (subPage === 'admin') return <Admin />;
    
    // subPage가 없으면 기본 Component를 렌더링
    return <Component />;
}

export default DynamicPage;
