import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import winterMusicFile from "../asset/music/winter.mp3";

const LeeSooJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이정환♥이수진 결혼합니다.";
    const openDesc = "2025년 5월 10일 토 아현정 오후 1시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/78/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // 빈티지 연핑크
        concept: "SPRING",
        titleColor: "#edacb1",
        pointColor: "#edacb1",
        btnBgColor: "#edacb1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "이정환",
            bank: "IM뱅크",
            accountNumber : "508-13-4796959",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "이경수",
            bank: "하나은행",
            accountNumber : "084-18-28069-8",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "이성희",
            bank: "우리은행",
            accountNumber : "840-07-047438",
            isKakao : false
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "이수진",
            bank: "국민은행",
            accountNumber : "821701-01-532207",
            isKakao : false
        },
        // {
        //     role: "신부 부",
        //     name: "어철",
        //     bank: "농협은행",
        //     accountNumber : "207054-52-409958",
        //     isKakao : false
        // },
        {
            role: "신부 모",
            name: "손옥태",
            bank: "IM뱅크",
            accountNumber : "163-13-019894",
            isKakao : false
        }
    ]

    const msg1 = "함께하는 일곱번째 따스한 봄,\n새로운 출발을 하고자 합니다.";
    const msg2 = "오랫동안 쌓아온 신뢰와 확신으로\n서로의 가장 친한 친구가 되어\n사랑을 나누는 행복한 부부가 되겠습니다.";
    const msg3 = "그 시작을 알리는 소중한 날\n함께 축복해 주시면 감사하겠습니다."


    const lng = 128.625627645429;
    const lat =  35.84759323482;

    const placeTelNum = "053-766-2100";

    const desc = "어린이세상 공영주차장 옆 위치해있습니다.";

    const forBus = "어린이세상 앞 100, 234, 814, 8140, 수성1, 급행3\n어린이세상 건너1 100-1, 수성1-1, 급행3\n어린이세상 건너2 204\n대구고용노동청 앞 100-1, 수성1-1\n대구고용노동청 건너 100, 234, 814, 8140, 수성1";
    const forSubway = "지하철 3호선 \n어린이세상역 1번 출구 도보 6분\n황금역 1번 출구 도보 16분\n수성구민운동장역 1번 출구 도보 10분";
    const forRentBus = "5월10일(토) 오전 8시30분 출발\n사당역 공영주차장 (사당역 1번출구 나와서 직진 50m앞 \n우측) \n\n우주항공여행사 경기7자3827\n최무현기사님 010-5212-9065"

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "눈에 하트 뿅"
        },
        {
            question : "우리 커플을 소개한다면?",
            groomAnswer: "미녀와 야수",
            brideAnswer: "원빈 이나영"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생 잘할게",
            brideAnswer: "유부초밥 짱"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "잘 살게요❤️"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ffd139"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"이정환 이수진 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forRentBus={forRentBus} desc={desc} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LeeSooJin;
