import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Account_Spring from '../component/Spring/Account_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import GalleryList from '../component/Gallery/GalleryList';

const Winter = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#7b7b7b",
        btnBgColor: "#000000",
        fontColor: "#000",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const brideAccountBank = "카카오뱅크"
    const brideAccount = "123-456-78910"

    const groomAccountBank = "신한은행"
    const groomAccount = "110-489-78910"

    const msg1 = "함박눈 고운 겨울날 , \n 저희 두 사람이 \n 서로의 마음에 깊은 사랑의 언약을 하려 합니다.";
    const msg2 = "기쁨이든 슬픔이든 \n 평생을 함께하며 나눌 소중한 사랑 \n 축복과 격려주시면 더 없는 기쁨이 되겠습니다.";

    const lng = 129.100265672;
    const lat = 35.145592665;

    const placeTelNum = "051-668-7979";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const forCar = "네비게이션 “부산광역시 남구 황령대로 401-9” 입력";
    const forBus = "남천역 4번 출구 남천더샵프레스티지 103동 인근\n셔틀버스 무료 운행 (배차간격 10-20분)";
    const forParking = "그랜드모먼트 건물 지하 5층, 지하1층, 지하주차장, 타워주차장";
    const forSubway = "부산 2호선 남천역 4번 출구 (도보 10분)";

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "어떻게 잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려요",
            brideAnswer: "설레 죽겠다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "잘해줄게요",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이♥"
        }
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "최정호",
            bank: "농협은행",
            accountNumber : "789-45665-552-4",
            isKakao : true,
            kakaoUrl: ""
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김가영",
            bank: "부산은행",
            accountNumber : "123-456-7899",
            isKakao : true,
            kakaoUrl: ""
        }
    ]
    
    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패1:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList color={conceptStyle.pointColor} fontFamilyKo={conceptStyle.fontFamilyKo} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring 
                                    weddingData={weddingData}
                                    goToGuestBook={goToGuestBook} 
                                    weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forBus={forBus} forParking={forParking} forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default Winter;