import React from 'react';
import flower from "../../asset/flower.png";

const ParentInfo = ({ conceptStyle, isBold, parentInfo = { groom: {}, bride: {} }, weddingData = {} }) => {
    const renderParentName = (parent) => {
        if (!parent) return "N/A";
        return (
            <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {parent.isDeceased && (
                    <img
                        src={flower}
                        alt="flower"
                        style={{ width: "20px"}}
                    />
                )}
                {parent.name || "N/A"}
            </span>
        );
    };

    return (
        <div>
            <div
                style={{
                    fontFamily: conceptStyle?.fontFamilyKo || "Arial, sans-serif",
                    color: conceptStyle?.baseFontColor || "#313131",
                    fontWeight: isBold ? 600 : 300,
                    textAlign: "center",
                    fontSize: "13px",
                    justifyContent: "center",
                    marginBottom: "70px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "260px",
                        height: "60px",
                        margin: "0 auto",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div style={{ fontSize: "14px", width: "80px"}}>
                        <p>{renderParentName(parentInfo?.groom?.dad)}</p>
                        <p>{renderParentName(parentInfo?.groom?.mom)}</p>
                    </div>
                    <span
                        style={{
                            width: "60px",
                            // padding: "0 3px",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <p>의</p>
                        <p>{parentInfo?.groom?.role || "역할 없음"}</p>
                    </span>
                    <p style={{ fontSize: "14px", width: "70px", textAlign: "left", paddingLeft: "10px"}}>
                        {weddingData?.groomName || "이름 없음"}
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "260px",
                        height: "60px",
                        margin: "0 auto",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div style={{ fontSize: "14px", width: "80px" }}>
                        <p>{renderParentName(parentInfo?.bride?.dad)}</p>
                        <p>{renderParentName(parentInfo?.bride?.mom)}</p>
                    </div>
                    <span
                        style={{
                            width: "60px",
                            // padding: "0 3px",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <p>의</p>
                        <p>{parentInfo?.bride?.role || "역할 없음"}</p>
                    </span>
                    <p style={{ fontSize: "14px", width: "70px", textAlign: "left", paddingLeft: "10px"}}>
                        {weddingData?.brideName || "이름 없음"}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ParentInfo;
