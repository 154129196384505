import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const LeeHyunJoo = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이수용 ♥ 이현주 결혼합니다. ";
    const openDesc = "2025.04.19 (SAT) 11:00 AM\nWESTERN BENNEVIS";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/110/thumb.jpg";

    const lng = 126.896124031689;
    const lat = 37.5277473772256;

    const placeTelNum = "02-6297-7000";

    const forCar = "1) 현대해상 강서지점 서울 영등포구 당산로 141 \n2) 삼성생명 에이원타워 당산서울 영등포구 국회대로 559 \n\n* 웨딩홀에는 주차장이 없습니다. \n'웨딩 고객 전용 주차장'에 주차 부탁드립니다. \n* 웨딩홀 1층 입구에서 주차 등록 후 출차 바랍니다. \n(2시간 무료 주차)";
    const forBus = "5, 70-3, 5620, 6637, 6631, 영등포02, 영등포12";
    const forSubway = "2·5호선 영등포구청역 3번 출구에서 200m (도보 2분)"


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summber 핑크B
        concept: "SUMMER",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ff8faf",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const msg1 = "예쁜 예감이 들었다. \n우리는 언제나 손을 잡고 있게 될 것이다.";
    const msg2 = "이이체, <연인>"
    const msg3 = "\n날이 좋은 어느 평범했던 날,\n우연처럼 만나 운명처럼 사랑에 빠져 \n이제 서로의 손을 잡고 그 시작의 자리에 \n소중한 분들을 모시고 싶습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "이수용",
            bank: "우리은행",
            accountNumber : "1002-759-375249",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "이충호",
            bank: "SC제일은행",
            accountNumber : "130-30-031173",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "권경희",
            bank: "신한은행",
            accountNumber : "110-195-016503",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이현주",
            bank: "우리은행",
            accountNumber : "1002-053-160950",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "이성남",
            bank: "국민은행",
            accountNumber : "223-21-0929-176",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이미해",
            bank: "국민은행",
            accountNumber : "645525-01-015399",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "엄청 떨렸지",
            brideAnswer: "귀여웠었어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 설렌다",
            brideAnswer: "두근두근해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "나도 사랑해",
            brideAnswer: "평생행복해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "아껴줄게요",
            brideAnswer: "난 항상 네편"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이♥️"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "문병관",
            call: "01056503499"
        }
        ,
        {
            role: "신랑 부",
            name: "문사권",
            call: "01066360179"
        },
        {
            role: "신랑 모",
            name: "황혜영",
            call: "01065360179"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이하린",
            call: "01021867191"
        }
        ,{
            role: "신부 부",
            name: "이홍강",
            call: "01064699689"
        }
        ,{
            role: "신부 모",
            name: "박윤옥",
            call: "01057734553"
        }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#313131"} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ff8faf"} dateColor={"#313131"} bottom={"84%"} bottom2={"75%"} dateBottom={"5%"} top={"1%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default LeeHyunJoo;