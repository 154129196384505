import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';


const KimDooYi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "장현진♥김두이 결혼합니다.";
    const openDesc = "2025년 5월 10일 토요일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/83/thumb.jpg";

    const placeTelNum = "033-650-7000";

    const lng = 128.9151686924897;
    const lat = 37.79722272737966;

    const forTaxi = "1) 강릉역에서 이용 시 약 15분 소요\n2) 고속버스 터미널에서 이용 시 약 20분 소요";
    const forCar = "네비게이션 씨마크호텔 검색";
    const forParking = "주차 요원의 안내를 받아 야외 주차장에 주차 하시고, \n웨딩홀 주차장 만차 시 호텔 주차장으로 안내 도와드립니다.";
    const forShuttleBus = "강릉역에서 씨마크 호텔까지 셔틀 버스 운영 됩니다.\n\n강릉역 3번 출구 앞 택시 승강장 건너편 지정 장소에서 탑승\n※ 이용 시 약 20분 소요\n※ 시즌별 시간 변경으로 호텔 홈페이지 참조";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "장현진",
            bank: "국민은행",
            accountNumber : "302502-04-115347",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "장맹수",
            bank: "국민은행",
            accountNumber : "619-24-0022-404",
            isKakao : false
        },

        {
            role: "신랑 모",
            name: "김명순",
            bank: "국민은행",
            accountNumber : "827502-96-123543",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김두이",
            bank: "카카오뱅크",
            accountNumber : "3333-01-3476144",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "김정환",
            bank: "하나은행",
            accountNumber : "620-170562-806",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "안윤숙",
            bank: "하나은행",
            accountNumber : "010-71276039-707",
            isKakao : false
        }
    ]


    const msg1 = "저희 둘 함께 웃던 기억들이 \n사랑이라는 이름으로 \n서로에게 마음 속 깊이 자리 잡았습니다.";
    const msg2 = "인생의 여정을 시작하는 첫 날을\n함께 축복해 주시면 감사드리겠습니다.";


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "눈이 예쁘네",
            brideAnswer: "오 깔끔하다"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "고지가 눈앞"
        },
        {
            question : "결혼을 결심한 이유는?",
            groomAnswer: "그냥 너라서",
            brideAnswer: "귀여워서~"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: " 말을 잘 듣자",
            brideAnswer: "행복하자잉"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "꽁냥꽁냥",
            brideAnswer: "잘 살게요❤️"
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김태석",
            call: "010-2339-5728"
        },
        {
            role: "신랑 부",
            name: "김현수",
            call: "010- 5476-7571"
        },
        {
            role: "신랑 모",
            name: "박주희",
            call: "010-4109-0115 "
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "임다슬",
            call: "010-5158-2492"
        },
        {
            role: "신부 부",
            name: "임성환",
            call: "010-8570-2492"
        },
        {
            role: "신부 모",
            name: "권명옥",
            call: "010-9365-2492"
        }
    ]

    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter isFormatWeddingTime={true} width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} isFormatWeddingTime={true}/>
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forCar={forCar} forShuttleBus={forShuttleBus} forTaxi={forTaxi} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default KimDooYi;