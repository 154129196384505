import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const LimSuBin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    

    const openTitle = "이건형 ❤︎ 임수빈의 결혼식에 초대합니다.";
    const openDesc = "05.11 11:00AM";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/53/thumb1.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // spring 그린A
        concept: "SPRING",
        titleColor: "#c7eb00",
        pointColor: "#c7eb00",
        btnBgColor: "#f7fea4",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fffff3",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "이건형",
            bank: "농협은행",
            accountNumber : "196-12-060701",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "000",
            bank: "국민은행",
            accountNumber : "623501-01-520666",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "000",
            bank: "농협은행",
            accountNumber : "589-02-012840",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "임수빈",
            bank: "농협은행",
            accountNumber : "302-0940-1195-11",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "000",
            bank: "국민은행",
            accountNumber : "240-21-0574-165",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "000",
            bank: "우리은행",
            accountNumber : "1002-733-227515",
            isKakao : false
        }
    ]
    
    const msg1 = "2007년,\n친구 사이로 처음 만나\n2022년,\n따뜻한 봄날, 인연으로 다시 만나게 되었습니다.\n2025년, \n이젠 연인에서 부부로 함께하려고 합니다.";
    const msg2 = "향기로운 오월, 여러분의 따스한 바람으로 \n저희의 봄날을 가득 채워주시길 소망합니다.";

    const lng = 127.0807686;
    const lat = 37.06171;

    const placeTelNum = "031-611-1414";

    const forCar = "네이게이션에 “국제웨딩컨벤션” 입력\n\n주차장: 국제컨벤션센터 주차장 이용\n(국제대학교 전체 주차 시설 이용 가능)\n국제대학교 정문 쪽에 위치한 가장 큰 건물\n무료 주차";
    const forBus = "코오롱하늘채아파트동문 or 코오롱하늘채아파트북문 하차\n버스 : 5-3, 7, 88A, 88B, 99"
    const forSubway = "1호선 서정리역 하차 -> 셔틀버스 국제웨딩컨벤션 도착\nSRT 지제역 하차 -> 1호선 서정리역 하차 \n-> 셔틀버스 국제웨딩컨벤션 도착";
    const forShuttleBus = "1호선 서정리역 1번 출구 ↔ 국제웨딩컨벤션\n- 서정리역 출발 : 매시 5분, 35분\n- 국제웨딩컨벤션 출발 : 매시 20분, 50분\n(예식 1시간 전부터 예식 2시간 후까지 운행)"

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "어떻게 잊어",
            brideAnswer: "엇 번듯하다"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "빨리하고파",
            brideAnswer: "실감이 안나"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "평생함께해",
            brideAnswer: "멋있게 살자"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "굶기진 않음",
            brideAnswer: "동의하는 바"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "사랑합니다",
            brideAnswer: "항상고마워"
        }
    ]
    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#F6C766"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forShuttleBus={forShuttleBus} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LimSuBin;
