import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import musicFile from '../asset/music/우효-민들레.mp3';


const SungSangHee = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "조성훈 ❤ 성상희 결혼합니다.";
    const openDesc = "봄처럼 따사로운 시작을 축복해주시면 감사하겠습니다.";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/103/thumb2.jpg";

    const lng = 127.04657799848736;
    const lat = 37.50328288108072;

    const placeTelNum = "02-2183-0230";

    const forBus = "한국자산신탁, 르네상스호텔, 서울상록회관 정류장 기준\n- Blue 간선 : N13, N61, 146, 341, 360, 740 \n- Red 광역 : 1100, 1700, 2000, 2000-1, 7007, 8001,\n 9303\n\n※ 선릉역 방향 이동 두꺼비 빌딩 지나 30M \n직진 한신인터밸리24 빌딩, 4층\n[ 선릉역 1번, 10번 출구 정류장 기준 ]\n- Blue 간선 : N13, N61, 146, 333, 341, 360, 740 \n- Red 광역 : 1100, 1700, 2000, 7007, 8001, 9303, \n9414\n- 공항버스 : 6000\n\n※ 선릉역 1번, 10번 출구 앞 정류장 하차 후 역삼역 방향으로 이동, 선릉역 4번 출구에서 100M 직진 시 좌측 \n한신인터밸리24 빌딩, 4층";
    const forSubway = "선릉역(2호선, 분당선) 4번 출구로 나와 \n ➡️ IBK기업은행을 지나 50M 직진\n➡️ 맥도날드 옆 한신인터밸리24빌딩 4층";
    const forParking = "'한신인터밸리24 주차장'을 검색 시 \n건물 뒷편 주차장 입구로 안내됩니다.";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // const conceptStyle = {
    //     // spring 그린 c
    //     concept: "SPRING",
    //     titleColor: "#157f01",
    //     pointColor: "#157f01",
    //     btnBgColor: "#66ce4d",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#ebf4e9",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    // #FCA008
    // #A9B6A0
    // #fad83e

    // const conceptStyle = {
    //     concept: "SPRING",
    //     titleColor: "#fad83e",
    //     pointColor: "#fad83e",
    //     btnBgColor: "#ffe49f",
    //     fontColor: "#ffffff",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#fff9e9",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "평생의 단짝을 만나 함께하는 첫걸음,";
    const msg2 = "봄처럼 따사로운 시작을 축복해주시면 감사하겠습니다.";

    const groomAccountList = [
        // {
        //     role: "신랑",
        //     name: "천강민",
        //     bank: "기업은행",
        //     accountNumber : "576-018569-01-014",
        //     isKakao : true,
        //     kakaoUrl : "https://qr.kakaopay.com/FbMAfcl5g"
        // }
        {
            role: "신랑 부",
            name: "조달현",
            bank: "국민은행",
            accountNumber : "813-21-0053-927",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김미숙",
            bank: "신한은행",
            accountNumber : "110-007-120598",
            isKakao :false
        }
    ]

    const brideAccountList = [
        // {
        //     role: "신부",
        //     name: "김진숙",
        //     bank: "농협은행",
        //     accountNumber : "197-12-390677",
        //     isKakao : true,
        //     kakaoUrl : "https://qr.kakaopay.com/Ej8c7mkxF"
        // }
        {
            role: "신부 부",
            name: "성락중",
            bank: "신한은행",
            accountNumber : "110-272-371090",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "손영혜",
            bank: "신한은행",
            accountNumber : "110-438-545663",
            isKakao :false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "천강민",
            call: "010-4806-9489"
        }
        ,{
            role: "신랑 부",
            name: "천경식",
            call: "010-8989-7591"
        }
        ,{
            role: "신랑 모",
            name: "김현자",
            call: "010-8905-7591"
        }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김진숙",
            call: "010-2834-2582"
        },
        {
            role: "신부 부",
            name: "김도길",
            call: "010-4285-5671"
        },
        {
            role: "신부 모",
            name: "박대심",
            call: "010-9386-5681"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "꽤당돌하네",
            brideAnswer: "걍웃긴오빠"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "올게왔구나",
            brideAnswer: "떨리진않아"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보자",
            brideAnswer: "항상고마워"
        },
        {
            question : "서로에게 바라는 점은?",
            groomAnswer: "스팸구워줘",
            brideAnswer: "네가해먹어"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나먼저간다",
            brideAnswer: "나도가용"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forSubway={forSubway} forParking={forParking} forBus={forBus} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default SungSangHee;