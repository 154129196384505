import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import winterMusicFile from "../asset/music/winter.mp3";
import CallInfo from '../component/CallInfo';

const LimSoHee = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "상협 ♥ 소희 결혼합니다.";
    const openDesc = "25.04.26 토요일 오후 12시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/82/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "유상협",
            bank: "하나은행",
            accountNumber : "62891048606907",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8SVB7Ci"
        },
        {
            role: "신랑 부",
            name: "유영세",
            bank: "하나은행",
            accountNumber : "640-200314-00108",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김정미",
            bank: "하나은행",
            accountNumber : "648-135444-00108",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "임소희",
            bank: "국민은행",
            accountNumber : "95184221464",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8xArQix"
        },
        {
            role: "신부 부",
            name: "임채일",
            bank: "우리은행",
            accountNumber : "033 231821 12 001",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "천종림",
            bank: "신한은행",
            accountNumber : "110 252 462166",
            isKakao : false
        }
    ]

    const msg1 = "봄처럼 포근하고 여름처럼 뜨겁고\n가을처럼 충만하고 겨울처럼 설레는 사람을 만났습니다.";
    const msg2 = "상협이와 소희가 이제 서로의 모든 계절을 함께 지나려 합니다.\n가장 빛나는 날, 늘 곁에서 아껴준 고마운 분들을 초대합니다.";

    const lng = 127.746818;
    const lat = 34.749595;

    const placeTelNum = "061-662-7333";

    const forCar = "🚘 전남 여수시 박람회길 1 → 국제관 A/B 동 주차 후 \n축의대에서 주차권 수령 (3시간 무료)";
    const forBus = "🚍 여수시외버스터미널에서 시내버스 이용\n→ 666,28,89,73,35(윗길행) 버스 승차\n→ 동초등학교 버스정류장 하차 후 도보이동 (10분)";
    const forTrain2 = "🚅 여수엑스포역 하차 → 역 앞 횡단보도 건너 직진 \n(도보 약 5분거리)"

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    // const parents = [
    //     {groom : "김준호 · 윤소영의 아들"},
    //     {bride : "故김종수 · 홍희숙의 딸"}
    // ]
    
    const groomCallInfoList = [
        {
            role: "신랑",
            name: "유상협",
            call: "010-7438-2214"
        }
        ,{
            role: "신랑 부",
            name: "유영세",
            call: "010-8808-4561"
        },
        {
            role: "신랑 모",
            name: "김정미",
            call: "010-8343-4656"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "임소희",
            call: "010-5184-2214"
        }
        ,{
            role: "신부 부",
            name: "임채일",
            call: "010-5500-1151"
        }
        ,{
            role: "신부 모",
            name: "천종림",
            call: "010-5685-0727"
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "눈에 하트 뿅"
        },
        {
            question : "우리 커플을 소개한다면?",
            groomAnswer: "미녀와 야수",
            brideAnswer: "원빈 이나영"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생 잘할게",
            brideAnswer: "유부초밥 짱"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "잘 살게요❤️"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
            <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                        <MotionWrapper initialY={70}>
                            <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forCar={forCar} forTrain2={forTrain2} forBus={forBus} conceptStyle={conceptStyle}/>
                                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper>
            </>
            )}
        </div>
    );
};

export default LimSoHee;
