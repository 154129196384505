import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const HeoJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "강성 ♡ 허진 결혼합니다.";
    const openDesc = "4월 13일 일요일 12시10분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/96/thumb.jpeg";

    const lng = 126.904586710033;
    const lat = 37.5213571324809;

    const placeTelNum = "02-6418-3000";

    const forParking = "- 건물 주차장 이용 가능\n- 영남 주차장(상황에 따라 운영합니다.)\n\n주말에는 차량이 많아 여유롭게 오시길 권장드립니다.";
    const forBus = "[영등포 시장 하차] \n간선버스(파랑) - 605, 661, 761번\n지선버스(초록) - 5616, 5714, 6514번\n광역버스(빨강) - 9707, M7646번\n공항버스 - 6007번\n직행버스 - 1082, 1500, 8000, 9030, 9030-1번\n좌석버스 - 700, 830, 870, 871번\n일반버스 - 5, 60, 60-3, 88번"
    const forSubway = "- 지하철 5호선 영등포시장역 하차 4번출구 \n- 지하철 1호선 영등포역 하차\n영등포 뉴타운 지하상가 4번 출구 (새길병원방면)"
    const forCar = "'서울 영등포구 영중로 55' 또는 \n'서울 영등포구 영등포동6가 7' 네비게이션 검색\n\n건물 내 주차장 이용 가능하나, 주차 공간이 협소할 수 \n있기에 가급적 대중교통 이용을 추천드립니다. 🙏💕"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const msg1 = "같이 있으면 매일이 즐겁고\n서로일 때 가장 나다워지는 평생의 반려자와\n새로운 인생을 시작하려 합니다.";
    const msg2 = "저희 두 사람의 첫걸음에 함께하여\n자리를 빛내주시면\n더없이 감사하겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "강성",
            bank: "하나은행",
            accountNumber : "257-910645-55007",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FMIzFBH3m"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "최경일",
        //     bank: "농협은행",
        //     accountNumber : "714-12-335617",
        //     isKakao : false
        // },
        // {
        //     role: "신랑 모",
        //     name: "김진우",
        //     bank: "농협은행",
        //     accountNumber : "150073-52-158527",
        //     isKakao : false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "허진",
            bank: "하나은행",
            accountNumber : "368-910060-34407",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej80fAY4w"
        },
        {
            role: "신부 부",
            name: "허인수",
            bank: "농협은행",
            accountNumber : "125-02-299428",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "한미경",
            bank: "농협은행",
            accountNumber : "125-02-396682",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "찾았다요놈",
            brideAnswer: "잡았다요놈"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "우리잘살자",
            brideAnswer: "너무설렌다"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생내꾸해",
            brideAnswer: "평생행복해"
        },
    ]

const groomCallInfoList = [
        {
            role: "신랑",
            name: "최태규",
            call: "010 8834 4976"
        }
        // ,
        // {
        //     role: "신랑 부",
        //     name: "강기수",
        //     call: "010 3747 9533"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김미숙",
        //     call: "010 9067 1678"
        // }
    ]
    const brideCallInfoList = [
        {
            role: "신부",
            name: "김보현",
            call: "010 2186 7191"
        }
        // ,
        // {
        //     role: "신부 부",
        //     name: "김현석",
        //     call: "010 6386 7792"
        // },
        // {
        //     role: "신부 모",
        //     name: "박채희",
        //     call: "010 5899 3546"
        // }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#ffffff"} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ff8faf"} dateColor={"#ffffff"} bottom={"22%"} bottom2={"13%"} dateBottom={"82%"} top={"5%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)} enToKoDayOfWeek={true}/>
                    <Letter title={"저희 결혼합니다!"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default HeoJin;