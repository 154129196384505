import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';

const SeongHeeGee = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김도훈 ♥︎ 성희지 결혼합니다.";
    const openDesc = "2월9일 오전 11시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/42/thumb.jpeg";

    const placeTelNum = "02-3409-0123";

    const forSubway = "수인분당선 서울숲역 5번출구 도보 2분\n2호선 뚝섬역 8번출구 도보 5분"
    const forBus = "간선버스(파랑색) 뚝섬 서울숲 정류장\n121,141,145,148,463\n\n지선버스(녹색) 성동구민 종합 체육센터 정류장\n2014,2224,2413 \n\n지선버스(녹색) 뚝섬역 8번 출구 정류장\n2016,2224,2413";
    const forParking = "건물 내 B3-B7 2시간 무료주차(안내데스크 주차 등록 시)";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김도훈",
            bank: "농협은행",
            accountNumber : "302-0720-2795-71",
            isKakao : true,
            kakoUrl : "https://qr.kakaopay.com/FChl41UNS"
        },
        {
            role: "신랑 부",
            name: "김학섭",
            bank: "농협은행",
            accountNumber : "147-02-167521",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "정우숙",
            bank: "농협은행",
            accountNumber : "130035-52-144811",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "성희지",
            bank: "카카오뱅크",
            accountNumber : "3333-04-3239319",
            isKakao : true,
            kakoUrl : "https://qr.kakaopay.com/FJ33XGTcC"
        },
        {
            role: "신부 부",
            name: "성태헌",
            bank: "기업은행",
            accountNumber : "112-223684-04-016",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "신동희",
            bank: "신한은행",
            accountNumber : "110-029-389538",
            isKakao : false
        }
    ]

    const msg1 = "매년 2월 9일이 되면, \n저희는 이 날의 사진첩을 꺼내보며 \n인생에서 가장 행복했던 기억을 떠올릴 것입니다.";
    const msg2 = "그 때, 그 사진속에,\n저희와 함께 웃고 있는 여러분이 있었으면 좋겠습니다.";

    const lng = 127.042914714267;
    const lat = 37.5456773286722;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "서로의 첫인상은?",
            groomAnswer: "순둥이선임",
            brideAnswer: "똑쟁이후임"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "정말떨려요",
            brideAnswer: "넘설렙니다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘챙겨줄게",
            brideAnswer: "언제나네편"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "늘웃겨줄게",
            brideAnswer: "늘웃어줄게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "배려하면서",
            brideAnswer: "살겠습니다"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.groomNameEn)} groomNameEn={getFirstNameEn(weddingData.brideNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={"소중한 분들을 초대합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={4} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forSubway={forSubway} forBus={forBus} forParking={forParking} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default SeongHeeGee;